<template>
    <ReportLayout 
    v-if="reportData.tableSessionsStarted && reportData.tableUniqueUsers"
    :radioName="radioName" 
    :radioLogo="radioLogo" 
    reportName="Relatório de Audiência" 
    :periodStart="periodStart" 
    :periodEnd="periodEnd"
    :numberOfPages="calculateNumberOfPages()">
    <template v-slot:page-1>
        <div class="md-layout-item md-size-100" 
            style="margin: 0 auto;">
            <ReportCard
                :title="'Dados consolidados'" 
                :labels="['Total de sessões', 'Total de usuários únicos', 'Média de sessões por dia', 'Média de usuários únicos por dia']"
                :keys="['totalSessions', 'totalUnique', 'averageSessions', 'averageUnique']" 
                :cardData="[{
                    totalSessions: $options.filters.formatNumber(reportData.sumTotalSessions),
                    totalUnique: $options.filters.formatNumber(reportData.sumTotalUnique),
                    averageSessions: $options.filters.formatNumber(reportData.sumTotalSessions / reportData.numberOfDays),
                    averageUnique: $options.filters.formatNumber(reportData.sumTotalUnique / reportData.numberOfDays)
                }]" 
                :cardDisplay="'template'"
                :dataDisplay="'big-columns'">
                <template v-slot:report-subtitle-footer>
                    <strong>*CTR: </strong>
                    <i>Click-through rate</i>,
                    traduzindo, taxa de cliques. Divisão da quantidade
                    de cliques pela de visualizações.
                </template>
            </ReportCard>
        </div>
        <div class="md-layout-item md-size-50" style="margin: 0 auto;">
            <ReportCardPieChart
				v-if="reportData.uniqueUsersFilteredByOS"
				chartName="Quantidade de usuários"
                title="Usuários por sistema operacional"
				:chartData="reportData.uniqueUsersFilteredByOS"
			/>
        </div>
        <div class="md-layout-item md-size-50" style="margin: 0 auto;">
            <ReportCardPieChart
				v-if="reportData.uniqueUsersFilteredByVersion"
                title="Usuários por versão"
				chartName="Quantidade de usuários"
				:chartData="reportData.uniqueUsersFilteredByVersion"
			/>
        </div>
    </template>
    <ReportCard 
        v-for="index in Math.ceil(reportData.tableSessionsStarted.length/16)"
        :slot="'page-'+(index+1)"
        :key="index"
            :title="'Sessões iniciadas'" 
            :labels="['Data', 'Android', 'iOS', 'Alexa', 'Não Definido', 'TOTAL']"
            :keys="['data', 'android', 'ios', 'alexa_count', 'not_defined', 'TOTAL']" 
            :cardData="reportData.tableSessionsStarted.slice((index-1) * 16, (index-1)*16 + 16)"
            :dataDisplay="'long-table'"
            :extraRows="index === Math.ceil(reportData.tableSessionsStarted.length/16)?buildExtraRowSessionsStarted():null"
            >
    </ReportCard>
    <ReportCard 
        v-for="index in Math.ceil(reportData.tableUniqueUsers.length/16)"
        :slot="'page-'+(Math.ceil(reportData.tableUniqueUsers.length/16)+index+1)"
        :key="index"
        :title="'Usuários únicos'"  
        :labels="['Data', 'Android', 'iOS', 'Alexa', 'Não Definido', 'TOTAL']"
        :keys="['data', 'android', 'ios', 'alexa', 'not_defined', 'TOTAL']" 
        :cardData="reportData.tableUniqueUsers.slice((index-1) * 16, (index-1)*16 + 16)"
        :dataDisplay="'long-table'"
        :extraRows="index === Math.ceil(reportData.tableUniqueUsers.length/16)?buildExtraRowUniqueUsers():null"
    >
    </ReportCard>
    <ReportCard
        ref="radios-consolidated-table"
        v-if="radiosConsolidated.length>0"
        :slot="'page-'+calculateNumberOfPages()"
        :title="'Rádios consolidadas'"
        :labels="['']"
        :keys="['name']"
        :cardData="radiosConsolidated"
        :dataDisplay="'long-table'"
    />
</ReportLayout>
</template>
<script>
import Vue from "vue";
import ReportLayout from "./partials/ReportLayout.vue";
import ReportCard from "./partials/ReportCard.vue";
import numeral from "numeral";
import ReportCardPieChart from './partials/ReportCardPieChart.vue'

numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

export default {
	name: "TemplateReportAudience",
	components: {
		ReportLayout,
		ReportCard,
        ReportCardPieChart
	},
	props: [
		"radioName",
        "radiosConsolidated",
		"radioLogo",
		"periodStart",
		"periodEnd",
		"reportData"
	],
    methods: {
        calculateNumberOfPages(){
            let numberOfPagesForSessions = Math.ceil(this.$props.reportData.tableSessionsStarted.length/16)
            let numberOfPagesForUsers = Math.ceil(this.$props.reportData.tableUniqueUsers.length/16)

            let numberOfTotalPages = numberOfPagesForSessions + numberOfPagesForUsers + 1

            if(this.$props.radiosConsolidated.length>0){
                numberOfTotalPages += 1
            }

            if(numberOfTotalPages===0){
                return 1
            }

            return numberOfTotalPages
        },
        buildExtraRowUniqueUsers(){
            let props = this.$props.reportData
            let filters = this.$options.filters
            return [
                {
                    label: 'MÉDIA/DIA',
                    android: filters.formatNumber(props.sumAndroidUnique/props.tableUniqueUsers.length),
                    ios: filters.formatNumber(props.sumIosUnique/props.tableUniqueUsers.length),
                    alexa: filters.formatNumber(props.sumAlexaUnique/props.tableUniqueUsers.length),
                    not_defined: filters.formatNumber(props.sumNotDefinedUnique/props.tableUniqueUsers.length),
                    total: filters.formatNumber(props.sumTotalUnique/props.tableUniqueUsers.length)
                }
            ]
        },
        buildExtraRowSessionsStarted(){
            let props = this.$props.reportData
            let filters = this.$options.filters

            return [
                {
                    label: 'TOTAL',
                    android: filters.formatNumber(props.sumAndroidSessions),
                    ios: filters.formatNumber(props.sumIosSessions),
                    alexa: filters.formatNumber(props.sumAlexaSessions),
                    not_defined: filters.formatNumber(props.sumNotDefinedSessions),
                    total: filters.formatNumber(props.sumTotalSessions)
                },
                {
                    label: 'MÉDIA/DIA',
                    android: filters.formatNumber(props.sumAndroidUnique/props.tableUniqueUsers.length),
                    ios: filters.formatNumber(props.sumIosUnique/props.tableUniqueUsers.length),
                    alexa: filters.formatNumber(props.sumAlexaUnique/props.tableUniqueUsers.length),
                    not_defined: filters.formatNumber(props.sumNotDefinedUnique/props.tableUniqueUsers.length),
                    total: filters.formatNumber(props.sumTotalUnique/props.tableUniqueUsers.length)
                }
            ]
        }
    }
};
</script>

<style>
</style>