<template>
    <md-card class="report-card-template">
        <md-card-header>
            <h3 class="main-title">{{title}}</h3>
        </md-card-header>
        <md-card-content>
            <div class="md-layout md-alignment-center-space-around">
                <div v-if="chartData.length===0" class="md-layout-item md-size-100" ref="error-no-info">
                    <h3 class="error-no-info" ref="error-no-info">Sem informações para serem exibidas</h3>
                </div>
                <div v-show="chartData.length > 0" class="md-layout-item md-size-100" ref="pieChartContainer">
                    
                </div>
            </div>
        </md-card-content>
        <md-card-footer>
            <h5 class="report-subtitle">
                <slot name="report-subtitle-footer"></slot>
            </h5>
        </md-card-footer>
    </md-card>
</template>

<script>
import numeral from "numeral"
import Vue from 'vue'
import Highcharts from "highcharts";

numeral.locale("pt-br")

Vue.filter("formatNumber", function(value) {
	return numeral(value).format("0,0")
});

export default {
    name: "ReportCardPieChartView",
    props: {
        title: String,
        chartName: String,
        chartData: Array
    },
    methods: {
        buildPieChart(){
            const chartContainer = this.$refs.pieChartContainer

            Highcharts.chart(chartContainer, {
				chart: {
					type: "pie",
                    height: 300,
                    width: 250,
                    animation: false
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
                        format: '{point.percentage:.1f}%',
						dataLabels: {
							enabled: false
						},
						showInLegend: true
					}
				},
				credits: false,
				title: {
					text: ""
				},
				exporting: {
					enabled: false
				},
				series: [
					{
						name: this.$props.chartName,
						data: this.$props.chartData,
					}
				]
			})
        }
    },
    watch: {
        chartData: function(val){
            this.buildPieChart()
        }
    }
}

</script>

<style scoped>
.remove-margin-and-padding {
    margin: 0;
    padding: 0;
}
.report-card-template {
    background-color: #ffffff;
	border: 1px solid #009ddb;
}

.main-title{
	font-weight: bold !important;
	text-align: center;
	margin: 10px 0 !important;
}

.secondary-title {
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: bold;
}

.error-no-info {
    padding: 20%;
}

</style>