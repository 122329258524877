<template>
    <md-card :class="`report-card-view ${dataDisplay}`">
        <md-card-header>
            <h3 class="main-title">{{title}}</h3>
        </md-card-header>
        <md-card-content style="padding: 0;">
            <div class="md-layout-item md-size-100">
                <slot name="report-subtitle-header"></slot>
            </div>
            <div class="md-layout md-alignment-center-space-around">
                <template v-if="cardData.length!==0">
                    <div class="table-row md-layout-item md-size-100">
                        <div class="md-layout md-alignment-center-center">
                            <template v-for="label in labels">        
                                <div :class="`md-layout-item md-size-${calculateColumnSizeForLabels()}`" style="padding: 0;">
                                    <h5 class="secondary-title">{{label}}</h5>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="table-row md-layout-item md-size-100" v-for="element in cardData">
                        <div class="md-layout md-alignment-center-center">
                            <template v-for="key in keys">
                                <p :class="`md-layout-item md-size-${calculateColumnSizeForLabels()} text-content`">
                                    {{ element[key] }}
                                </p>
                            </template>
                        </div>
                    </div>
                    <div
                        class="table-row md-layout-item md-size-100"
                        v-if="extraRows"
                        v-for="(extraRow, index) in extraRows"
                        :ref="`extra-row-${index}`"
                    >
                        <div class="md-layout md-alignment-center-center">
                            <template v-for="key in Object.keys(extraRow)">
                                <p :class="`md-layout-item md-size-${calculateColumnSizeForLabels()} text-content extra-row`">
                                    {{ extraRow[key] }}
                                </p>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="md-layout-item md-size-100">
                        <h5 class="secondary-title">Sem informações para serem exibidas</h5>
                    </div>
                </template>
            </div>
        </md-card-content>
        <md-card-footer>
            <h5 class="report-subtitle">
                <slot name="report-subtitle-footer"></slot>
            </h5>
        </md-card-footer>
    </md-card>
</template>

<script>
import numeral from "numeral"
import Vue from 'vue'

numeral.locale("pt-br")
Vue.filter("formatNumber", function(value) {
	return numeral(value).format("0,0")
});

export default {
    name: "ReportCardTableView",
    props: {
        title: String,
        dataDisplay: String, /*short-table, long-table or big-columns*/
        labels: Array,
        keys: Array,
        cardData: Array,
        extraRows: Array
    },
    methods: {
        calculateColumnSizeForLabels(){
            let quantityOfLabels = this.$props.keys.length
            let columnSize = Math.floor(100 / quantityOfLabels)

            return columnSize
        },
        formatAllNumbersFromData(){
            const keys = Object.keys(this.$props.cardData[0])

            for(let i=0; i<this.$props.cardData.length; i++){
                for (let key of keys) {
                    if(typeof(this.$props.cardData[i][key])==="number"){
                        let formatedNumber = numeral(this.$props.cardData[i][key]).format("0,0")
                        this.$props.cardData[i][key] = formatedNumber
                    }
                }
            }
        },
        formatExtraRows(){
            const keysFromExtraLines = Object.keys(this.$props.extraRows[0])

            for(let i=0; i<this.$props.extraRows.length; i++){
                for (let key of keysFromExtraLines) {
                    if(typeof(this.$props.extraRows[i][key])==="number"){
                        let formatedNumber = numeral(this.$props.extraRows[i][key]).format("0,0")
                        this.$props.extraRows[i][key] = formatedNumber
                    }
                }
            }
        }
    },
    watch: {
        cardData: function(){
            this.formatAllNumbersFromData()
        },
        extraRows: function(){
            this.formatExtraRows()
        }
    }
}

</script>

<style scoped>

.extra-row {
    font-weight: 500;
}
.remove-margin-and-padding {
    margin: 0;
    padding: 0;
}
.report-card-view {
	background-color: #ffffff;
    min-height: 90%;
}

.main-title{
    font-weight: bold !important;
	text-align: center;
	font-size: 170%;
	margin: 24px 0 !important;
}

.secondary-title {
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
    text-transform: uppercase;
}

.short-table .secondary-title, .long-table .secondary-title {
    margin-bottom: 14px;
}

.text-content {
    font-size: 14px;
    margin: 0;
    padding: 10px;
    text-align: center;
    text-wrap: nowrap;
}

.long-table .table-row * {
    text-align: center;
}


.table-row:nth-child(odd){
	background: #fff
}

.table-row:nth-child(even){
	background: #dcf5ff46;
}

.short-table .text-content {
    font-size: 18px;
    margin: 0;
    padding: 10px;
}

.short-table .table-row .text-content:first-child {
    text-align: left;
}
.short-table .table-row .text-content:last-child{
    text-align: right;
}

.big-columns .text-content{
	font-size: 22px;
    padding: 12px 0;
}

.report-subtitle {
    font-size: 10px;
    text-align: left;
    padding: 0 0 1rem 1rem;
    margin: 0;
}


</style>