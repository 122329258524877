<template>
	<div class="md-layout">
		<div
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<FilterSelector
				ref="filter-selector"
				@searchData="getAllPromises()"
				@extraFunction="printReport()"
				:catalogReportAvailable="true"
				:buttonsToDisplay="[
					buttonEnum.YESTERDAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM,
				]"
				:extraFunction="true"
				:extraFunctionLabel="'IMPRIMIR'"
				:disableExtraFunction="tableSessionsStared.length === 0"
			>
			</FilterSelector>
		</div>
		<div
			v-show="loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<Spinner />
		</div>
		<div
			v-show="this.tableSessionsStared.length > 0 && !loading"
			class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-33 md-xlarge-size-33 principal"
		>
			<ReportCardTableView
				title="Dados consolidados"
				dataDisplay="short-table"
				:labels="[]"
				:keys="['label', 'data']"
				:cardData="[
					{
						label: 'Total de sessões',
						data: this.sumTotalSessions,
					},
					{
						label: 'Total de usuários únicos',
						data: this.sumTotalUnique,
					},
					{
						label: 'Média de sessões por dia',
						data: Math.floor(sumTotalSessions / numberOfDays),
					},
					{
						label: 'Média de usuários únicos por dia',
						data: Math.floor(sumTotalUnique / numberOfDays),
					},
				]"
			/>
		</div>

		<div
			v-show="this.tableSessionsStared.length > 0"
			class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-33 md-xlarge-size-33 principal"
		>
			<ReportCardPieChartView
				v-if="this.tableSessionsStared.length > 0"
				chartName="Quantidade de usuários"
				:chartData="this.formatDataForOSchart()"
				title="Usuários por sistema operacional"
			/>
		</div>

		<div
			v-show="this.tableSessionsStared.length > 0"
			class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-33 md-xlarge-size-33 principal"
		>
			<ReportCardPieChartView
				v-if="this.tableSessionsStared.length > 0"
				chartName="Quantidade de usuários"
				:chartData="this.uniqueUsersFilteredByVersion"
				title="Usuários por versão"
			/>
		</div>
		<div class="md-layout" v-show="!loading">
			<div
				v-show="this.tableSessionsStared.length > 0"
				class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-50 md-xlarge-size-50 principal"
			>
				<ReportCardTableView
					title="Sessões iniciadas"
					dataDisplay="long-table"
					:labels="['Data', 'Android', 'iOS', 'Alexa', 'Não definido', 'Total']"
					:keys="[
						'data',
						'android',
						'ios',
						'alexa_count',
						'not_defined',
						'TOTAL',
					]"
					:cardData="this.tableSessionsStared"
					:extraRows="[
						{
							label: 'TOTAL',
							android: this.sumAndroidSessions,
							ios: this.sumIosSessions,
							alexa: this.sumAlexaSessions,
							not_defined: this.sumNotDefinedSessions,
							total: this.sumTotalSessions,
						},
						{
							label: 'MÉDIA/DIA',
							android:
								this.sumAndroidSessions / this.tableSessionsStared.length,
							ios: this.sumIosSessions / this.tableSessionsStared.length,
							alexa: this.sumAlexaSessions / this.tableSessionsStared.length,
							not_defined:
								this.sumNotDefinedSessions / this.tableSessionsStared.length,
							total: this.sumTotalSessions / this.tableSessionsStared.length,
						},
					]"
				/>
			</div>

			<div
				v-show="this.tableUniqueUsers.length > 0"
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-50 md-xlarge-size-50 principal"
			>
				<ReportCardTableView
					title="Usuários únicos"
					dataDisplay="long-table"
					:labels="['Data', 'Android', 'iOS', 'Alexa', 'Não definido', 'Total']"
					:keys="['data', 'android', 'ios', 'alexa', 'not_defined', 'TOTAL']"
					:cardData="this.tableUniqueUsers"
					:extraRows="[
						{
							label: 'MÉDIA/DIA',
							android: this.sumAndroidUnique / this.tableUniqueUsers.length,
							ios: this.sumIosUnique / this.tableUniqueUsers.length,
							alexa: this.sumAlexaUnique / this.tableUniqueUsers.length,
							not_defined:
								this.sumNotDefinedUnique / this.tableUniqueUsers.length,
							total: this.sumTotalUnique / this.tableUniqueUsers.length,
						},
					]"
				/>
			</div>

			<div
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<md-card style="text-align: center" v-show="msgError">
					<md-card-content>
						<label for="">Sem informações a serem exibidas.</label>
					</md-card-content>
				</md-card>
			</div>
		</div>

		<TemplateReportAudience
			id="report"
			:radioName="
				this.radio.parent_name ? this.radio.parent_name : this.radio.name
			"
			:radioLogo="this.radio.logo"
			:periodStart="this.periodStart"
			:periodEnd="this.periodEnd"
			:parentName="this.radio.parent_name"
			:radiosConsolidated="this.radiosConsolidated"
			:reportData="{
				tableSessionsStarted: this.tableSessionsStared,
				tableUniqueUsers: this.tableUniqueUsers,

				sumAndroidSessions: this.sumAndroidSessions,
				sumIosSessions: this.sumIosSessions,
				sumAlexaSessions: this.sumAlexaSessions,
				sumNotDefinedSessions: this.sumNotDefinedSessions,
				sumTotalSessions: this.sumTotalSessions,

				sumAndroidUnique: this.sumAndroidUnique,
				sumIosUnique: this.sumIosUnique,
				sumAlexaUnique: this.sumAlexaUnique,
				sumNotDefinedUnique: this.sumNotDefinedUnique,
				sumTotalUnique: this.sumTotalUnique,

				uniqueUsersFilteredByOS: this.formatDataForOSchart(),
				uniqueUsersFilteredByVersion: this.uniqueUsersFilteredByVersion,
				numberOfDays: this.tableSessionsStared.length,
			}"
		/>
	</div>
</template>

<script>
import RadioCatalogItem from "../Components/RadioCatalogItem.vue";
import apiSingleton from "../../../routes/apiSingleton.js";
import { ChartCard } from "@/components";
import selectRadio from "../../../utils/events/select-radio.js";
import jwt_decode from "jwt-decode";
import { DatePicker, Checkbox, CheckboxGroup } from "element-ui";
import TemplateReportAudience from "../ReportTemplates/TemplateReportAudience.vue";
import Spinner from "../../../components/Icons/Spinner";
import formatDateToSQL from "../../../utils/formatDateToSQL";
import swal from "sweetalert2";
import ReportCardTableView from "../Components/ReportComponents/ReportCardTableView.vue";
import ReportCardPieChartView from "../Components/ReportComponents/ReportCardPieChartView.vue";
import FilterSelector from "../Components/FilterSelector/FilterSelector.vue";
import filters from "../Components/FilterSelector/filters";
import buttonEnum from "../Components/FilterSelector/buttonEnum";

export default {
	components: {
		ChartCard,
		RadioCatalogItem,
		[DatePicker.name]: DatePicker,
		[CheckboxGroup.name]: CheckboxGroup,
		[Checkbox.name]: Checkbox,
		TemplateReportAudience,
		Spinner,
		FilterSelector,
		ReportCardTableView,
		ReportCardPieChartView,
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			tableSessionsStared: [],
			tableUniqueUsers: [],
			widthPicker: 100 + "%",
			loading: false,
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			radiosConsolidated: [],
			periodStart: null,
			periodEnd: null,
			msgError: false,
			nameRadioReport: jwt_decode(localStorage.getItem("APP_01")).radio.name,
			logo: "",
			btnImprimir: true,
			totalAccessAndroid: 0,
			totalAccessIOS: 0,
			datePickerOptions: {
				disabledDate: (date) => {
					return date < new Date("2020-1-1");
				},
			},
			sumAndroidSessions: 0,
			sumIosSessions: 0,
			sumAlexaSessions: 0,
			sumTotalSessions: 0,
			sumAndroidUnique: 0,
			sumIosUnique: 0,
			sumAlexaUnique: 0,
			sumNotDefinedUnique: 0,
			sumTotalUnique: 0,
			uniqueUsersFilteredByVersion: [],
			numberOfDays: 0,
		};
	},
	methods: {
		async getAudienceSessionsPerDay(appKey, startDate, endDate) {
			return apiSingleton.get(
				`report/getAudienceSessionsPerDay?appKey=${appKey}&startDate=${startDate}&endDate=${endDate}`
			);
		},
		async getAudienceSessionsPerDayCatalog(idRadios, startDate, endDate) {
			return apiSingleton.get(
				`report/getAudienceSessionsPerDayCatalog?idsRadios=${idRadios}&startDate=${startDate}&endDate=${endDate}`
			);
		},
		async getAudienceAverageUniqueUsers(appKey, startDate, endDate) {
			return apiSingleton.get(
				`report/getAudienceAverageUniqueUsers?appKey=${appKey}&startDate=${startDate}&endDate=${endDate}`
			);
		},
		async getAudienceAverageUniqueUsersCatalog(idRadios, startDate, endDate) {
			return apiSingleton.get(
				`report/getAudienceAverageUniqueUsersCatalog?idsRadios=${idRadios}&startDate=${startDate}&endDate=${endDate}`
			);
		},
		async getUniqueUsersPerVersion(appKey, startDate, endDate) {
			return apiSingleton.get(
				`report/getAudienceAveragePerDay?appKey=${appKey}&startDate=${startDate}&endDate=${endDate}`
			);
		},

		async getUniqueUsersPerVersionCatalog(appKey, startDate, endDate) {
			return apiSingleton.get(
				`report/getAudienceAveragePerDayCatalog?idsRadios=${appKey}&startDate=${startDate}&endDate=${endDate}`
			);
		},
		calculateTotalSessionsPerPlatform() {
			this.sumAndroidSessions = 0;
			this.sumIosSessions = 0;
			this.sumNotDefinedSessions = 0;
			this.sumAlexaSessions = 0;
			this.sumTotalSessions = 0;

			for (let item of this.tableSessionsStared) {
				this.sumAndroidSessions += parseInt(item.android);
				this.sumIosSessions += parseInt(item.ios);
				this.sumAlexaSessions += parseInt(item.alexa_count);
				this.sumNotDefinedSessions += parseInt(item.not_defined);
				this.sumTotalSessions += parseInt(item.TOTAL);
			}
		},
		calculateTotalUniqueUsersPerPlatform() {
			this.sumAndroidUnique = 0;
			this.sumIosUnique = 0;
			this.sumTotalUnique = 0;
			this.sumAlexaUnique = 0;
			for (let item of this.tableUniqueUsers) {
				this.sumAndroidUnique += parseInt(item.android);
				this.sumIosUnique += parseInt(item.ios);
				this.sumTotalUnique += parseInt(item.TOTAL);
				this.sumAlexaUnique += parseInt(item.alexa);
				this.sumNotDefinedUnique += parseInt(item.not_defined);
			}
		},

		getAllDistinctVersions(uniqueUsersData) {
			let versionsFiltered = uniqueUsersData.map((item) => item.app_version);
			let setOfVersions = new Set(versionsFiltered);
			return [...setOfVersions];
		},

		formatUsersByVersionToBuildChart(uniqueUsersData) {
			let versions = this.getAllDistinctVersions(uniqueUsersData);

			let dataSet = [];
			for (let version of versions) {
				let uniqueUsersOfCurrentVersion = uniqueUsersData
					.filter((item) => item.app_version === version)
					.map((item) => item.unique_users);

				let uniqueUsersSummedUp = uniqueUsersOfCurrentVersion.reduce(
					(accumulator, currentVal) => accumulator + currentVal,
					0
				);
				let percentageOfCurrentVersion =
					(uniqueUsersSummedUp * 100) / this.sumTotalUnique;

				if (percentageOfCurrentVersion > 0) {
					let pieSlice = {
						name: `${version} (${percentageOfCurrentVersion.toFixed(1)}%)`,
						y: uniqueUsersSummedUp,
					};
					dataSet.push(pieSlice);
				}
			}
			return dataSet;
		},
		formatDataForOSchart() {
			let platforms = {
				Android: this.sumAndroidUnique,
				iOS: this.sumIosUnique,
				Alexa: this.sumAlexaUnique,
				"Não Definido": this.sumNotDefinedUnique,
			};

			let dataFormated = [];
			Object.keys(platforms).forEach((key) => {
				let numberOfUsers = platforms[key];
				let platformPercent = (
					(numberOfUsers * 100) /
					this.sumTotalUnique
				).toFixed(1);

				if (platformPercent !== "0.0") {
					dataFormated.push({
						name: `${key} (${platformPercent}%)`,
						y: numberOfUsers,
					});
				}
			});

			return dataFormated;
		},
		getAllPromises() {
			this.loading = true;
			this.tableSessionsStared = [];
			this.tableUniqueUsers = [];

			const formatDateStart = new Date(filters.startDate);
			const formatDateEnd = new Date(filters.endDate);

			this.periodStart = filters.startDate.toLocaleDateString("pt-BR");
			this.periodEnd = filters.endDate.toLocaleDateString("pt-BR");

			let dateStart = `${formatDateToSQL(formatDateStart)} 00:00:00`;
			let dateEnd = `${formatDateToSQL(formatDateEnd)} 23:59:59`;

			let arrayPromise = [];

			if (this.radio.parent_id) {
				this.radiosConsolidated = filters.$options.radiosConsolidated;
				let idsString = this.radiosConsolidated
					.map((radio) => radio.id)
					.join(",");

				arrayPromise.push(
					this.getAudienceSessionsPerDayCatalog(idsString, dateStart, dateEnd)
				);
				arrayPromise.push(
					this.getAudienceAverageUniqueUsersCatalog(
						idsString,
						dateStart,
						dateEnd
					)
				);
				arrayPromise.push(
					this.getUniqueUsersPerVersionCatalog(idsString, dateStart, dateEnd)
				);
			} else {
				arrayPromise.push(
					this.getAudienceSessionsPerDay(this.radio.key, dateStart, dateEnd)
				);
				arrayPromise.push(
					this.getAudienceAverageUniqueUsers(this.radio.key, dateStart, dateEnd)
				);
				arrayPromise.push(
					this.getUniqueUsersPerVersion(this.radio.key, dateStart, dateEnd)
				);
			}

			Promise.all(arrayPromise)
				.then((resolve) => {
					this.tableSessionsStared = resolve[0].data;
					if (this.tableSessionsStared.length == 0) {
						this.msgError = true;
						return;
					}
					this.tableSessionsStared.map((item) => {
						item.data = new Date(item.data).toLocaleDateString("pt-BR");
						return item;
					});
					this.numberOfDays = this.tableSessionsStared.length;

					this.tableUniqueUsers = resolve[1].data;
					this.tableUniqueUsers.map((item) => {
						item.data = new Date(item.data).toLocaleDateString("pt-BR");
						return item;
					});

					this.calculateTotalSessionsPerPlatform();
					this.calculateTotalUniqueUsersPerPlatform();

					this.uniqueUsersFilteredByVersion =
						this.formatUsersByVersionToBuildChart(resolve[2].data);

					//this.buildUsersPerPlatformGraph()
					//this.buildUsersPerVersionGraph()

					this.msgError = false;
				})
				.catch((e) => {
					console.log(e);
					swal.fire(
						"Ops!",
						"Houve um erro ao tentar recuperar os dados para o relatório. Caso o erro persista, entre em contato com o suporte",
						"error"
					);
					this.msgError = true;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		formatPeriod(selectedDate) {
			const monthNames = [
				"Janeiro",
				"Fevereiro",
				"Março",
				"Abril",
				"Maio",
				"Junho",
				"Julho",
				"Agosto",
				"Setembro",
				"Outubro",
				"Novembro",
				"Dezembro",
			];

			const d = new Date(selectedDate);
			this.periodStart = monthNames[d.getMonth()] + "/" + d.getFullYear();
		},
		clearAllData() {
			(this.tableSessionsStared = []),
				(this.tableUniqueUsers = []),
				(this.selectedClose1 = null),
				(this.selectedClose2 = null),
				(this.time1 = null),
				(this.time2 = null),
				(this.timeFormatedBr = null),
				(this.datepicker = null),
				(this.widthPicker = 100 + "%"),
				(this.loading = false),
				(this.radio = jwt_decode(localStorage.getItem("APP_01")).radio),
				(this.appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key),
				(this.radiosConsolidated = []),
				(this.periodStart = null),
				(this.periodEnd = null),
				(this.msgError = false),
				(this.nameRadioReport = jwt_decode(
					localStorage.getItem("APP_01")
				).radio.name),
				(this.logo = ""),
				(this.btnImprimir = true),
				(this.totalAccessAndroid = 0),
				(this.totalAccessIOS = 0),
				(this.datePickerOptions = {
					disabledDate: (date) => {
						return date < new Date("2020-1-1");
					},
				}),
				(this.sumAndroidSessions = 0),
				(this.sumIosSessions = 0),
				(this.sumTotalSessions = 0),
				(this.sumAndroidUnique = 0),
				(this.sumIosUnique = 0),
				(this.sumTotalUnique = 0),
				(this.lastDay = 0);
		},
		printReport() {
			window.print();
		},
	},
	mounted() {
		if (jwt_decode(localStorage.getItem("APP_01")).radio) {
			this.appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key;
			this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo;
		}
		const today = new Date();
		const yesterday = new Date(today);

		yesterday.setDate(today.getDate() - 1);

		filters.buttonSelected = buttonEnum.YESTERDAY;
		filters.startDate = new Date(yesterday);
		filters.endDate = new Date(yesterday);
		filters.startDate.setHours(0, 0, 0);
		filters.endDate.setHours(23, 59, 59);

		this.$emit("updateFilters", filters);

		this.getAllPromises();

		selectRadio.$on("selectRadio", (payload) => {
			(this.appKey = payload.key), (this.nameRadioReport = payload.name);
			this.radio = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.logo = payload.logo;
			this.btnImprimir = true;
			this.msgError = false;
			this.tableSessionsStared = [];
			this.tableUniqueUsers = [];
			this.radiosConsolidated = [];
			this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo;

			this.getAllPromises();
		});
	},
};
</script>

<style scoped>
.impressao {
	display: none;
}

.md-table-head-label {
	font-weight: 800;
	color: #000000e0;
}

.td_tables,
.td_tables_media {
	width: 260px;
	padding: 10px 0px 10px 2px;
	text-align: center;
}

.border-icon-graph {
	display: inline-flex;
	padding: 20px;
	border-radius: 5px;
	position: absolute;
	box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2);
	background: linear-gradient(60deg, #26c6da, #00acc1);
}

.icon-graph {
	background-color: transparent !important;
	color: #ffffff;
}

.container-graph {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

.datepicker-center {
	margin-top: -14px;
}

.table_main,
.table_main_1 {
	border-collapse: collapse;
}

.tr_tables {
	border-bottom: none !important;
}
canvas {
	padding: 60px 40px 60px 60px;
	border-radius: 10px;
	box-shadow: 0px 0px 8px 0px #d9d9d9;
	background-color: #ffffff;
}

.md-card.main-rodape.md-theme-default {
	margin-top: 0px !important;
}
.title {
	font-weight: bold !important;
	text-align: center;
	font-size: 170%;
	margin: 24px 0 !important;
}
#report {
	position: absolute;
	top: -9999px;
	left: -9999px;
	visibility: hidden;
}

@media print {
	#report {
		position: static;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		visibility: visible;
	}

	tr {
		page-break-inside: avoid;
	}

	table.report-container {
		page-break-after: always !important;
	}

	thead.report-header {
		display: table-header-group !important;
	}

	tfoot.report-footer {
		display: table-footer-group !important;
		text-align: center;
		font-size: 12pt;
	}

	canvas {
		height: 300px !important;
		padding: 10px;
		border: 1px solid #d9d9d9;
		border-radius: 0px;
		box-shadow: 0px 0px 0px 0px;
		background-color: #ffffff;
		margin-top: -30px;
		margin-bottom: -40px;
	}

	img {
		width: 160px;
		height: 160px;
	}

	h1 {
		font-size: 18pt;
	}

	span {
		font-size: 12pt;
	}

	footer {
		text-align: center;
		font-size: 12pt;
	}

	.principal {
		display: none;
	}

	.impressao {
		display: block;
	}

	.impressao-content {
		display: flex;
	}

	.periodo {
		margin-top: 10px;
		width: 100%;
	}

	.md-toolbar-section-start {
		display: none;
	}

	.titleLeft {
		width: 100%;
	}

	.titleRight {
		float: right;
		width: 100%;
		text-align: right;
	}

	.nameRadio {
		font-size: 18pt;
	}

	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: 0px !important;
	}

	.contentBody {
		width: 100%;
		margin-top: 50px;
		padding: 1rem;
	}

	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 10px;
		width: 100%;
	}

	.card-icon {
		display: none;
	}

	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}

	.container-graph {
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.md-card.main-rodape.md-theme-default {
		margin-top: -300px;
	}

	.td_tables_media {
		width: 240px;
		padding: 10px 0px 10px 0px;
	}
}
</style>
