var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.reportData.tableSessionsStarted && _vm.reportData.tableUniqueUsers
    ? _c(
        "ReportLayout",
        {
          attrs: {
            radioName: _vm.radioName,
            radioLogo: _vm.radioLogo,
            reportName: "Relatório de Audiência",
            periodStart: _vm.periodStart,
            periodEnd: _vm.periodEnd,
            numberOfPages: _vm.calculateNumberOfPages(),
          },
          scopedSlots: _vm._u(
            [
              {
                key: "page-1",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "md-layout-item md-size-100",
                        staticStyle: { margin: "0 auto" },
                      },
                      [
                        _c("ReportCard", {
                          attrs: {
                            title: "Dados consolidados",
                            labels: [
                              "Total de sessões",
                              "Total de usuários únicos",
                              "Média de sessões por dia",
                              "Média de usuários únicos por dia",
                            ],
                            keys: [
                              "totalSessions",
                              "totalUnique",
                              "averageSessions",
                              "averageUnique",
                            ],
                            cardData: [
                              {
                                totalSessions:
                                  _vm.$options.filters.formatNumber(
                                    _vm.reportData.sumTotalSessions
                                  ),
                                totalUnique: _vm.$options.filters.formatNumber(
                                  _vm.reportData.sumTotalUnique
                                ),
                                averageSessions:
                                  _vm.$options.filters.formatNumber(
                                    _vm.reportData.sumTotalSessions /
                                      _vm.reportData.numberOfDays
                                  ),
                                averageUnique:
                                  _vm.$options.filters.formatNumber(
                                    _vm.reportData.sumTotalUnique /
                                      _vm.reportData.numberOfDays
                                  ),
                              },
                            ],
                            cardDisplay: "template",
                            dataDisplay: "big-columns",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "report-subtitle-footer",
                                fn: function () {
                                  return [
                                    _c("strong", [_vm._v("*CTR: ")]),
                                    _c("i", [_vm._v("Click-through rate")]),
                                    _vm._v(
                                      ", traduzindo, taxa de cliques. Divisão da quantidade de cliques pela de visualizações. "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1249786250
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "md-layout-item md-size-50",
                        staticStyle: { margin: "0 auto" },
                      },
                      [
                        _vm.reportData.uniqueUsersFilteredByOS
                          ? _c("ReportCardPieChart", {
                              attrs: {
                                chartName: "Quantidade de usuários",
                                title: "Usuários por sistema operacional",
                                chartData:
                                  _vm.reportData.uniqueUsersFilteredByOS,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "md-layout-item md-size-50",
                        staticStyle: { margin: "0 auto" },
                      },
                      [
                        _vm.reportData.uniqueUsersFilteredByVersion
                          ? _c("ReportCardPieChart", {
                              attrs: {
                                title: "Usuários por versão",
                                chartName: "Quantidade de usuários",
                                chartData:
                                  _vm.reportData.uniqueUsersFilteredByVersion,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1689859253
          ),
        },
        [
          _vm._l(
            Math.ceil(_vm.reportData.tableSessionsStarted.length / 16),
            function (index) {
              return _c("ReportCard", {
                key: index,
                attrs: {
                  slot: "page-" + (index + 1),
                  title: "Sessões iniciadas",
                  labels: [
                    "Data",
                    "Android",
                    "iOS",
                    "Alexa",
                    "Não Definido",
                    "TOTAL",
                  ],
                  keys: [
                    "data",
                    "android",
                    "ios",
                    "alexa_count",
                    "not_defined",
                    "TOTAL",
                  ],
                  cardData: _vm.reportData.tableSessionsStarted.slice(
                    (index - 1) * 16,
                    (index - 1) * 16 + 16
                  ),
                  dataDisplay: "long-table",
                  extraRows:
                    index ===
                    Math.ceil(_vm.reportData.tableSessionsStarted.length / 16)
                      ? _vm.buildExtraRowSessionsStarted()
                      : null,
                },
                slot: "page-" + (index + 1),
              })
            }
          ),
          _vm._l(
            Math.ceil(_vm.reportData.tableUniqueUsers.length / 16),
            function (index) {
              return _c("ReportCard", {
                key: index,
                attrs: {
                  slot:
                    "page-" +
                    (Math.ceil(_vm.reportData.tableUniqueUsers.length / 16) +
                      index +
                      1),
                  title: "Usuários únicos",
                  labels: [
                    "Data",
                    "Android",
                    "iOS",
                    "Alexa",
                    "Não Definido",
                    "TOTAL",
                  ],
                  keys: [
                    "data",
                    "android",
                    "ios",
                    "alexa",
                    "not_defined",
                    "TOTAL",
                  ],
                  cardData: _vm.reportData.tableUniqueUsers.slice(
                    (index - 1) * 16,
                    (index - 1) * 16 + 16
                  ),
                  dataDisplay: "long-table",
                  extraRows:
                    index ===
                    Math.ceil(_vm.reportData.tableUniqueUsers.length / 16)
                      ? _vm.buildExtraRowUniqueUsers()
                      : null,
                },
                slot:
                  "page-" +
                  (Math.ceil(_vm.reportData.tableUniqueUsers.length / 16) +
                    index +
                    1),
              })
            }
          ),
          _vm.radiosConsolidated.length > 0
            ? _c("ReportCard", {
                ref: "radios-consolidated-table",
                attrs: {
                  slot: "page-" + _vm.calculateNumberOfPages(),
                  title: "Rádios consolidadas",
                  labels: [""],
                  keys: ["name"],
                  cardData: _vm.radiosConsolidated,
                  dataDisplay: "long-table",
                },
                slot: "page-" + _vm.calculateNumberOfPages(),
              })
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }