var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [
          _c("FilterSelector", {
            ref: "filter-selector",
            attrs: {
              catalogReportAvailable: true,
              buttonsToDisplay: [
                _vm.buttonEnum.YESTERDAY,
                _vm.buttonEnum.SEVEN_DAYS,
                _vm.buttonEnum.MONTH_TO_DATE,
                _vm.buttonEnum.CUSTOM,
              ],
              extraFunction: true,
              extraFunctionLabel: "IMPRIMIR",
              disableExtraFunction: _vm.tableSessionsStared.length === 0,
            },
            on: {
              searchData: function ($event) {
                return _vm.getAllPromises()
              },
              extraFunction: function ($event) {
                return _vm.printReport()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [_c("Spinner")],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.tableSessionsStared.length > 0 && !_vm.loading,
              expression: "this.tableSessionsStared.length > 0 && !loading",
            },
          ],
          staticClass:
            "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-33 md-xlarge-size-33 principal",
        },
        [
          _c("ReportCardTableView", {
            attrs: {
              title: "Dados consolidados",
              dataDisplay: "short-table",
              labels: [],
              keys: ["label", "data"],
              cardData: [
                {
                  label: "Total de sessões",
                  data: this.sumTotalSessions,
                },
                {
                  label: "Total de usuários únicos",
                  data: this.sumTotalUnique,
                },
                {
                  label: "Média de sessões por dia",
                  data: Math.floor(_vm.sumTotalSessions / _vm.numberOfDays),
                },
                {
                  label: "Média de usuários únicos por dia",
                  data: Math.floor(_vm.sumTotalUnique / _vm.numberOfDays),
                },
              ],
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.tableSessionsStared.length > 0,
              expression: "this.tableSessionsStared.length > 0",
            },
          ],
          staticClass:
            "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-33 md-xlarge-size-33 principal",
        },
        [
          this.tableSessionsStared.length > 0
            ? _c("ReportCardPieChartView", {
                attrs: {
                  chartName: "Quantidade de usuários",
                  chartData: this.formatDataForOSchart(),
                  title: "Usuários por sistema operacional",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.tableSessionsStared.length > 0,
              expression: "this.tableSessionsStared.length > 0",
            },
          ],
          staticClass:
            "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-33 md-xlarge-size-33 principal",
        },
        [
          this.tableSessionsStared.length > 0
            ? _c("ReportCardPieChartView", {
                attrs: {
                  chartName: "Quantidade de usuários",
                  chartData: this.uniqueUsersFilteredByVersion,
                  title: "Usuários por versão",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "md-layout",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.tableSessionsStared.length > 0,
                  expression: "this.tableSessionsStared.length > 0",
                },
              ],
              staticClass:
                "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-50 md-xlarge-size-50 principal",
            },
            [
              _c("ReportCardTableView", {
                attrs: {
                  title: "Sessões iniciadas",
                  dataDisplay: "long-table",
                  labels: [
                    "Data",
                    "Android",
                    "iOS",
                    "Alexa",
                    "Não definido",
                    "Total",
                  ],
                  keys: [
                    "data",
                    "android",
                    "ios",
                    "alexa_count",
                    "not_defined",
                    "TOTAL",
                  ],
                  cardData: this.tableSessionsStared,
                  extraRows: [
                    {
                      label: "TOTAL",
                      android: this.sumAndroidSessions,
                      ios: this.sumIosSessions,
                      alexa: this.sumAlexaSessions,
                      not_defined: this.sumNotDefinedSessions,
                      total: this.sumTotalSessions,
                    },
                    {
                      label: "MÉDIA/DIA",
                      android:
                        this.sumAndroidSessions /
                        this.tableSessionsStared.length,
                      ios:
                        this.sumIosSessions / this.tableSessionsStared.length,
                      alexa:
                        this.sumAlexaSessions / this.tableSessionsStared.length,
                      not_defined:
                        this.sumNotDefinedSessions /
                        this.tableSessionsStared.length,
                      total:
                        this.sumTotalSessions / this.tableSessionsStared.length,
                    },
                  ],
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.tableUniqueUsers.length > 0,
                  expression: "this.tableUniqueUsers.length > 0",
                },
              ],
              staticClass:
                "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-50 md-xlarge-size-50 principal",
            },
            [
              _c("ReportCardTableView", {
                attrs: {
                  title: "Usuários únicos",
                  dataDisplay: "long-table",
                  labels: [
                    "Data",
                    "Android",
                    "iOS",
                    "Alexa",
                    "Não definido",
                    "Total",
                  ],
                  keys: [
                    "data",
                    "android",
                    "ios",
                    "alexa",
                    "not_defined",
                    "TOTAL",
                  ],
                  cardData: this.tableUniqueUsers,
                  extraRows: [
                    {
                      label: "MÉDIA/DIA",
                      android:
                        this.sumAndroidUnique / this.tableUniqueUsers.length,
                      ios: this.sumIosUnique / this.tableUniqueUsers.length,
                      alexa: this.sumAlexaUnique / this.tableUniqueUsers.length,
                      not_defined:
                        this.sumNotDefinedUnique / this.tableUniqueUsers.length,
                      total: this.sumTotalUnique / this.tableUniqueUsers.length,
                    },
                  ],
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
            },
            [
              _c(
                "md-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.msgError,
                      expression: "msgError",
                    },
                  ],
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("md-card-content", [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Sem informações a serem exibidas."),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("TemplateReportAudience", {
        attrs: {
          id: "report",
          radioName: this.radio.parent_name
            ? this.radio.parent_name
            : this.radio.name,
          radioLogo: this.radio.logo,
          periodStart: this.periodStart,
          periodEnd: this.periodEnd,
          parentName: this.radio.parent_name,
          radiosConsolidated: this.radiosConsolidated,
          reportData: {
            tableSessionsStarted: this.tableSessionsStared,
            tableUniqueUsers: this.tableUniqueUsers,

            sumAndroidSessions: this.sumAndroidSessions,
            sumIosSessions: this.sumIosSessions,
            sumAlexaSessions: this.sumAlexaSessions,
            sumNotDefinedSessions: this.sumNotDefinedSessions,
            sumTotalSessions: this.sumTotalSessions,

            sumAndroidUnique: this.sumAndroidUnique,
            sumIosUnique: this.sumIosUnique,
            sumAlexaUnique: this.sumAlexaUnique,
            sumNotDefinedUnique: this.sumNotDefinedUnique,
            sumTotalUnique: this.sumTotalUnique,

            uniqueUsersFilteredByOS: this.formatDataForOSchart(),
            uniqueUsersFilteredByVersion: this.uniqueUsersFilteredByVersion,
            numberOfDays: this.tableSessionsStared.length,
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }