var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    { class: `report-card-view` },
    [
      _c("md-card-header", [
        _c("h3", { staticClass: "main-title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("md-card-content", [
        _c(
          "div",
          { staticClass: "md-layout md-alignment-center-space-around" },
          [
            _vm.chartData.length === 0
              ? _c(
                  "div",
                  {
                    ref: "error-no-info",
                    staticClass: "md-layout-item md-size-100",
                  },
                  [
                    _c(
                      "h3",
                      { ref: "error-no-info", staticClass: "error-no-info" },
                      [_vm._v("Sem informações para serem exibidas")]
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.chartData.length > 0,
                  expression: "chartData.length > 0",
                },
              ],
              ref: "pieChartContainer",
              staticClass: "md-layout-item md-size-100",
            }),
          ]
        ),
      ]),
      _c("md-card-footer", [
        _c(
          "h5",
          { staticClass: "report-subtitle" },
          [_vm._t("report-subtitle-footer")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }